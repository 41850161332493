import React from "react";
import { Link, useParams } from "react-router-dom";
import products from "../products";

import "../styles/Product.scss";

const Product = () => {
  const { name } = useParams();
  const product = products.find(
    (p) => p.name.toLowerCase() === name.toLowerCase()
  );

  const allProducts = [
    "Remote (Kumanda)",
    "Dahili Sensör",
    "Harici Sensör (İş makinası)",
    "Monitor",
    "Sinyal Güçlendirici",
    "Uzaktan Erişim Cihazı",
    "Harici Sensör",
    "Kelepçe Sensör",
    "Harici Sensör (AFTES)"
  ];

  const filteredProducts = allProducts.filter((item) => item !== product.title);

  if (!product) {
    return <div>Product not found</div>;
  }
  return (
    <div className="product_page">
      <div className="product_page_container">
        <div className="product_page_item">
          <h1>{product.title}</h1>
          <img src={product.image} alt={product.name} className="mb-4" />
          <p>{product.description}</p>
          <h3>Özellikler</h3>
          <ul className="list-disc pl-5">
            {product.specifications.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
        <div className="w-1/3 product_page_list">
          <h3 className="text-xl font-semibold mb-2">Diğer Ürünler</h3>
          <ul className="bg-black text-white p-4 product_list">
            {filteredProducts.map((item, index) => (
              <li key={index} className="mb-2">
                {item === products[index].title ? (
                  <Link to={`/product/${products[index].name}`}>{item}</Link>
                ) : (
                  <Link to={`/product/${products[index + 1].name}`}>
                    {item}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Product;
